import React from 'react';

const App = () => {
  return (
    <div className="construction-page">
      <h1>Site Under Construction</h1>
      <p>We are currently working on something awesome. Stay tuned!</p>
    </div>
  );
}

export default App;
